@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme {
  .mat-mdc-unelevated-button {
    &.mat-unthemed {
      --mdc-filled-button-container-color: var(--avelon-theme-light-400);
      --mdc-filled-button-label-text-color: var(--avelon-theme-light-contrast-400);
      --mdc-filled-button-disabled-container-color: rgb(222, 223, 225, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-light-text-disabled);
    }

    &.mat-primary {
      --mdc-filled-button-container-color: var(--avelon-theme-light-700);
      --mdc-filled-button-label-text-color: var(--avelon-theme-light-contrast-700);
      --mdc-filled-button-disabled-container-color: rgb(188, 190, 195, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-light-text-disabled);
    }
  }
}

.avelon-theme.avelon-dark-theme {
  .mat-mdc-unelevated-button {
    &.mat-unthemed {
      --mdc-filled-button-container-color: var(--avelon-theme-dark-400);
      --mdc-filled-button-label-text-color: var(--avelon-theme-dark-contrast-400);
      --mdc-filled-button-disabled-container-color: rgb(61, 61, 61, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-dark-text-disabled);
    }

    &.mat-primary {
      --mdc-filled-button-container-color: var(--avelon-theme-dark-700);
      --mdc-filled-button-label-text-color: var(--avelon-theme-dark-contrast-700);
      --mdc-filled-button-disabled-container-color: rgb(87, 87, 87, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-dark-text-disabled);
    }
  }
}

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  button.mat-mdc-button,
  button.mat-mdc-unelevated-button:not(.download-button),
  a.mat-mdc-button,
  a.mat-flat-button:not(.download-button) {
    mat-icon {
      font-size: 3 * $base-size;
      width: 3 * $base-size;
      height: 3 * $base-size;
      vertical-align: middle;
      flex-shrink: 0;
    }

    .mdc-button__label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  button.mat-mdc-unelevated-button.download-button,
  a.mat-mdc-unelevated-button.download-button {
    box-sizing: content-box;
    min-width: 2 * $base-width;

    mat-icon + span,
    span + mat-icon {
      margin-top: $base-padding;
    }
  }

  button.mat-mdc-unelevated-button,
  a.mat-mdc-unelevated-button {
    border-radius: 3px;
    line-height: $base-height;
    min-height: $base-height;
    min-width: 100px;
    text-decoration: none;
    text-align: center;

    &.cdk-focused .mat-mdc-button-persistent-ripple {
      background-color: transparent;
      border: 2px solid black;
      opacity: 0.2;
    }
  }

  button.mat-mdc-icon-button {
    &.mat-mdc-button-base {
      font-size: inherit;
      width: $base-width;
      height: $base-height;
      padding: $base-padding;
    }

    .mat-mdc-button-touch-target {
      font-size: inherit;
      width: $base-width;
      height: $base-height;
    }

    .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }

  .mat-mdc-button-touch-target {
    display: none;
  }

  .mat-mdc-button-base.root-only {
    @include root-only();
  }
}
