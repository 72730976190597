@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Thin.woff2") format("woff2");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

.monospace {
  font-family: $font-family-mono;
}
