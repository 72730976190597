@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-color: var(--window-background);
  }

  .mat-mdc-dialog-title.mdc-dialog__title {
    font-family: inherit;
    font-weight: 500;
    font-stretch: normal;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: inherit;
    margin: 0;
    padding: 3 * $base-padding 3 * $base-padding 0 3 * $base-padding;
    display: flex;
    align-items: center;
  }

  .mat-mdc-dialog-content.mdc-dialog__content {
    color: inherit;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    padding: $base-padding 3 * $base-padding;
  }

  mat-dialog-actions.mat-mdc-dialog-actions,
  [mat-dialog-actions].mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding: 3 * $base-padding;
    min-height: $base-height;
  }
}
