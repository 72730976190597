@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-menu-content .mdc-list-item__primary-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mat-mdc-menu-item {
    min-height: $base-height;
    height: $base-height;
    line-height: $base-height;

    mat-icon.mat-icon {
      color: var(--icon-hue);
      flex: 0 0 auto;
    }
  }

  .mat-mdc-menu-panel {
    min-height: $base-height + 2 * $base-padding;
  }

  .mat-mdc-menu-item.root-only {
    @include root-only(false, true);
  }
}

.avelon-theme.avelon-dark-theme {
  .mat-mdc-menu-item.root-only {
    @include root-only(true, true);
  }
}
