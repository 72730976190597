@import "variables";

.grid-main-with-sidebar {
  grid-template-rows: 100%;
  grid-template-columns: minmax(300px, 20%) auto;
  grid-template-areas: "sidebar main";
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
}

.grid-auto-1fr {
  align-items: center;
  grid-template-columns: auto 1fr;
}

.grid-1fr-auto {
  align-items: center;
  grid-template-columns: 1fr auto;
}

.grid-1fr-1fr {
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.grid-auto-auto {
  align-items: center;
  grid-template-columns: auto auto;
}

.grid-container,
.grid-main-with-sidebar,
.grid-auto-1fr,
.grid-1fr-auto,
.grid-1fr-1fr,
.grid-auto-auto {
  display: grid;

  .grid-item-main,
  .grid-item-sidebar {
    box-sizing: border-box;
  }

  .grid-item-main {
    grid-area: main;
  }

  .grid-item-sidebar {
    grid-area: sidebar;

    mat-toolbar {
      border-right: 1px solid var(--primary-500);

      &.secondary-toolbar {
        border-right: 1px solid var(--primary-400);
      }
    }

    cdk-virtual-scroll-viewport {
      background-color: var(--window-background);
      border-right: 1px solid var(--primary-200);
    }
  }
}

.grid-gap {
  grid-gap: $base-gap;
}

.grid-column-gap {
  column-gap: $base-gap;
}

.grid-row-gap {
  row-gap: $base-gap;
}

.grid-justify-items-center {
  justify-items: center;
}
