@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-list-base .mat-mdc-list-item {
    font-size: $font-size;
    height: $base-height !important;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: var(--selected);
    color: var(--selected-text);

    .mdc-list-item__primary-text {
      color: var(--selected-text);
    }
  }
}
