.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-radio-button .mdc-form-field label {
    cursor: pointer;
  }
}

.avelon-theme.avelon-dark-theme {
  .mdc-radio__outer-circle,
  .mdc-radio__inner-circle {
    border-color: var(--text) !important;
  }
}