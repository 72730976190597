@use "sass:math";
@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .auto-width .mat-badge-content {
    padding-right: math.div($base-padding, 2);
    padding-left: math.div($base-padding, 2);
    border-radius: $base-size;
    min-width: $base-size;
    width: auto;
  }
}
