div.cg-busy {
  z-index: 1000;
}

$initial-logo-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
$bouncing-logo-timing-function: cubic-bezier(0.37, 0, 0.63, 1);

@keyframes logo-bar-1 {
  0% {
    transform: scaleY(0);
    animation-timing-function: $initial-logo-timing-function;
  }
  20% {
    transform: scaleY(1);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  40% {
    transform: scaleY(0.3);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  70% {
    transform: scaleY(0.7);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  100% {
    transform: scaleY(0.5);
  }
}

@keyframes logo-bar-2 {
  0% {
    transform: scaleY(0);
    animation-timing-function: $initial-logo-timing-function;
  }
  20% {
    transform: scaleY(1);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  40% {
    transform: scaleY(0.6);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  70% {
    transform: scaleY(1);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  100% {
    transform: scaleY(0.8);
  }
}

@keyframes logo-bar-3 {
  0% {
    transform: scaleY(0);
    animation-timing-function: $initial-logo-timing-function;
  }
  20% {
    transform: scaleY(1);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  40% {
    transform: scaleY(0.5);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  70% {
    transform: scaleY(0.8);
    animation-timing-function: $bouncing-logo-timing-function;
  }
  100% {
    transform: scaleY(0.66);
  }
}

@keyframes logo-bar-4 {
  0% {
    transform: scaleY(0);
    animation-timing-function: $initial-logo-timing-function;
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}

cg-busy {
  display: contents;

  .custom-template {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;

    svg rect {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      fill: var(--text);
      fill-opacity: 0.2;
      transform: scaleY(0);
      transform-origin: 0 22px;
      transition-property: scale;

      &:nth-child(1) {
        animation-name: logo-bar-1;
        animation-delay: 0ms;
      }

      &:nth-child(2) {
        animation-name: logo-bar-2;
        animation-delay: 100ms;
      }

      &:nth-child(3) {
        animation-name: logo-bar-3;
        animation-delay: 200ms;
      }

      &:nth-child(4) {
        animation-name: logo-bar-4;
        animation-delay: 300ms;
      }
    }
  }
}

.avelon-dark-theme .cg-busy-backdrop {
  background-color: var(--material-background);
}
