@use "@angular/material" as mat;
@import "variables";

// Define CSS custom properties for default color palette that are overridden by branding.
:root {
  // Main Avelon theme palette for light and dark mode.
  --avelon-theme-light-50: #f3f4f4;
  --avelon-theme-light-100: #eff0f1; // table headers hovered
  --avelon-theme-light-200: #eaeced; // table headers
  --avelon-theme-light-300: #e7e7e9; // secondary toolbar selected
  --avelon-theme-light-400: #dedfe1; // secondary toolbar hovered
  --avelon-theme-light-500: #d3d4d7; // secondary toolbar
  --avelon-theme-light-600: #c7c9cd; // primary toolbar selected
  --avelon-theme-light-700: #bcbec3; // primary toolbar hovered
  --avelon-theme-light-800: #b0b3b9; // primary toolbar
  --avelon-theme-light-900: #616a73; // topbar on dashboards
  --avelon-theme-light-A100: #a5a8af;
  --avelon-theme-light-A200: #616a73;
  --avelon-theme-light-A400: #1f2837;
  --avelon-theme-light-A700: #fd625e;
  --avelon-theme-light-contrast-50: #000000;
  --avelon-theme-light-contrast-100: #000000;
  --avelon-theme-light-contrast-200: #000000;
  --avelon-theme-light-contrast-300: #000000;
  --avelon-theme-light-contrast-400: #000000;
  --avelon-theme-light-contrast-500: #000000;
  --avelon-theme-light-contrast-600: #000000;
  --avelon-theme-light-contrast-700: #000000;
  --avelon-theme-light-contrast-800: #000000;
  --avelon-theme-light-contrast-900: #ffffff;
  --avelon-theme-light-contrast-A100: #ffffff;
  --avelon-theme-light-contrast-A200: #ffffff;
  --avelon-theme-light-contrast-A400: #ffffff;
  --avelon-theme-light-contrast-A700: #ffffff;

  --avelon-theme-dark-50: #222222;
  --avelon-theme-dark-100: #2b2b2b;
  --avelon-theme-dark-200: #333333;
  --avelon-theme-dark-300: #3d3d3d;
  --avelon-theme-dark-400: #454545;
  --avelon-theme-dark-500: #4e4e4e;
  --avelon-theme-dark-600: #575757;
  --avelon-theme-dark-700: #5f5f5f;
  --avelon-theme-dark-800: #686868;
  --avelon-theme-dark-900: #7a7a7a;
  --avelon-theme-dark-A100: #6b6b6b;
  --avelon-theme-dark-A200: #7e7e7e;
  --avelon-theme-dark-A400: #929292;
  --avelon-theme-dark-A700: #fd625e;
  --avelon-theme-dark-contrast-50: #ffffff;
  --avelon-theme-dark-contrast-100: #ffffff;
  --avelon-theme-dark-contrast-200: #ffffff;
  --avelon-theme-dark-contrast-300: #ffffff;
  --avelon-theme-dark-contrast-400: #ffffff;
  --avelon-theme-dark-contrast-500: #ffffff;
  --avelon-theme-dark-contrast-600: #ffffff;
  --avelon-theme-dark-contrast-700: #ffffff;
  --avelon-theme-dark-contrast-800: #ffffff;
  --avelon-theme-dark-contrast-900: #ffffff;
  --avelon-theme-dark-contrast-A100: #ffffff;
  --avelon-theme-dark-contrast-A200: #ffffff;
  --avelon-theme-dark-contrast-A400: #ffffff;
  --avelon-theme-dark-contrast-A700: #ffffff;

  // Accent colors
  --avelon-theme-light-blue: #039be5;
  --avelon-theme-light-green: #01b8aa;
  --avelon-theme-light-yellow: #f2cf00;
  --avelon-theme-light-orange: #ff922e;
  --avelon-theme-light-red: #fd625e;
  --avelon-theme-light-blue-contrast: #ffffff;
  --avelon-theme-light-green-contrast: #ffffff;
  --avelon-theme-light-yellow-contrast: #000000;
  --avelon-theme-light-orange-contrast: #000000;
  --avelon-theme-light-red-contrast: #ffffff;

  --avelon-theme-dark-blue: #039be5;
  --avelon-theme-dark-green: #01b8aa;
  --avelon-theme-dark-yellow: #f2cf00;
  --avelon-theme-dark-orange: #ff922e;
  --avelon-theme-dark-red: #fd625e;
  --avelon-theme-dark-blue-contrast: #ffffff;
  --avelon-theme-dark-green-contrast: #ffffff;
  --avelon-theme-dark-yellow-contrast: #000000;
  --avelon-theme-dark-orange-contrast: #ffffff;
  --avelon-theme-dark-red-contrast: #ffffff;

  // Accent colors for slightly tinted background (in table rows etc.)
  --avelon-theme-light-tint-blue: rgba(3, 155, 229, 0.2);
  --avelon-theme-light-tint-green: rgba(1, 184, 170, 0.2);
  --avelon-theme-light-tint-yellow: rgba(242, 207, 0, 0.2);
  --avelon-theme-light-tint-orange: rgba(255, 146, 46, 0.2);
  --avelon-theme-light-tint-red: rgba(253, 98, 94, 0.2);
  --avelon-theme-light-tint-blue-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-tint-green-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-tint-yellow-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-tint-orange-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-tint-red-contrast: var(--avelon-theme-light-text);

  --avelon-theme-dark-tint-blue: rgba(3, 155, 229, 0.2);
  --avelon-theme-dark-tint-green: rgba(1, 184, 170, 0.2);
  --avelon-theme-dark-tint-yellow: rgba(242, 207, 0, 0.2);
  --avelon-theme-dark-tint-orange: rgba(255, 146, 46, 0.2);
  --avelon-theme-dark-tint-red: rgba(253, 98, 94, 0.2);
  --avelon-theme-dark-tint-blue-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-tint-green-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-tint-yellow-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-tint-orange-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-tint-red-contrast: var(--avelon-theme-dark-text);

  // Message box colors
  --avelon-theme-light-message-blue: rgba(3, 155, 229, 0.3);
  --avelon-theme-light-message-green: rgba(1, 184, 170, 0.3);
  --avelon-theme-light-message-yellow: rgba(242, 207, 0, 0.3);
  --avelon-theme-light-message-orange: rgba(255, 146, 46, 0.3);
  --avelon-theme-light-message-red: rgba(253, 98, 94, 0.3);
  --avelon-theme-light-message-blue-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-message-green-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-message-yellow-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-message-orange-contrast: var(--avelon-theme-light-text);
  --avelon-theme-light-message-red-contrast: var(--avelon-theme-light-text);

  --avelon-theme-dark-message-blue: rgba(3, 155, 229, 0.3);
  --avelon-theme-dark-message-green: rgba(1, 184, 170, 0.3);
  --avelon-theme-dark-message-yellow: rgba(242, 207, 0, 0.3);
  --avelon-theme-dark-message-orange: rgba(255, 146, 46, 0.3);
  --avelon-theme-dark-message-red: rgba(253, 98, 94, 0.3);
  --avelon-theme-dark-message-blue-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-message-green-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-message-yellow-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-message-orange-contrast: var(--avelon-theme-dark-text);
  --avelon-theme-dark-message-red-contrast: var(--avelon-theme-dark-text);

  // Background colors
  --avelon-theme-light-view-background: var(--avelon-theme-light-50); // background behind cards or on inactive areas
  --avelon-theme-dark-view-background: var(--avelon-theme-dark-200);

  --avelon-theme-light-window-background: white; // lists, trees
  --avelon-theme-dark-window-background: var(--avelon-theme-dark-100);

  --avelon-theme-light-material-background: white; // windows, menus, dropdowns, cards
  --avelon-theme-dark-material-background: #424242;

  --avelon-theme-light-material-input-background: rgba(0, 0, 0, 0.04);
  --avelon-theme-dark-material-input-background: rgba(255, 255, 255, 0.1);

  --avelon-theme-light-drag-indicator-background: var(--avelon-theme-light-600);
  --avelon-theme-dark-drag-indicator-background: var(--avelon-theme-dark-600);

  // Text colors
  --avelon-theme-light-text: rgba(0, 0, 0, 0.8);
  --avelon-theme-dark-text: white;

  --avelon-theme-light-link: var(--avelon-theme-light-blue);
  --avelon-theme-dark-link: var(--avelon-theme-dark-blue);

  --avelon-theme-light-text-disabled: rgba(0, 0, 0, 0.38);
  --avelon-theme-dark-text-disabled: rgba(255, 255, 255, 0.38);

  --avelon-theme-light-icon-hue: rgba(0, 0, 0, 0.8);
  --avelon-theme-dark-icon-hue: var(--avelon-theme-dark-text);

  --avelon-theme-light-icon-hue-disabled: rgba(0, 0, 0, 0.26);
  --avelon-theme-dark-icon-hue-disabled: rgba(255, 255, 255, 0.26);

  --avelon-theme-light-hover: rgba(102, 108, 120, 0.1);
  --avelon-theme-dark-hover: rgba(255, 255, 255, 0.1);

  --avelon-theme-light-hover-text: var(--avelon-theme-light-contrast-50);
  --avelon-theme-dark-hover-text: var(--avelon-theme-dark-contrast-400);

  --avelon-theme-light-selected: var(--avelon-theme-light-300);
  --avelon-theme-dark-selected: var(--avelon-theme-dark-500);

  --avelon-theme-light-selected-text: var(--avelon-theme-light-contrast-300);
  --avelon-theme-dark-selected-text: var(--avelon-theme-dark-contrast-500);

  --avelon-theme-light-selected-shape-fill: rgba(160, 162, 170, 0.2);
  --avelon-theme-dark-selected-shape-fill: rgba(160, 162, 170, 0.2);

  --avelon-theme-light-selected-shape-stroke: #a0a2aa;
  --avelon-theme-dark-selected-shape-stroke: #a0a2aa;

  --avelon-theme-light-label-text: rgba(0, 0, 0, 0.7);
  --avelon-theme-dark-label-text: rgba(255, 255, 255, 0.7);

  --avelon-theme-light-box-border: rgba(0, 0, 0, 0.2);
  --avelon-theme-dark-box-border: rgba(255, 255, 255, 0.2);

  --avelon-theme-light-box-transparency: #{rgba(rgba(0, 0, 0, 0.2), 0)};
  --avelon-theme-dark-box-transparency: #{rgba(rgba(255, 255, 255, 0.2), 0)};

  // Module toolbar colors
  --avelon-theme-main-navigation-tint-left: rgba(0, 0, 0, 0.15);
  --avelon-theme-main-navigation-tint-text-left: inherit;
  --avelon-theme-main-navigation-tint-right: rgba(0, 0, 0, 0.15);
  --avelon-theme-main-navigation-tint-text-right: inherit;
  --avelon-theme-light-topbar: var(--avelon-theme-light-900);
  --avelon-theme-dark-topbar: var(--avelon-theme-dark-900);
  --avelon-theme-light-topbar-text: var(--avelon-theme-light-contrast-900);
  --avelon-theme-dark-topbar-text: var(--avelon-theme-dark-contrast-900);
  --avelon-theme-users-module: #447abe;
  --avelon-theme-users-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-devices-module: #01b8aa;
  --avelon-theme-devices-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-documents-module: #7550a9;
  --avelon-theme-documents-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-shop-module: #ffc828;
  --avelon-theme-shop-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-settings-module: #ffa837;
  --avelon-theme-settings-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-navigation-module: #5db3ec;
  --avelon-theme-navigation-module-text: var(--avelon-theme-dark-text);

  // TODO Extras not theme dependent? Also maybe we don't need all of these variables?
  --tooltip-background: rgba(97, 97, 97, 0.9);
}

$avelon-light-palette: (
  50: var(--avelon-theme-light-50),
  100: var(--avelon-theme-light-100),
  200: var(--avelon-theme-light-200),
  300: var(--avelon-theme-light-300),
  400: var(--avelon-theme-light-400),
  500: var(--avelon-theme-light-500),
  600: var(--avelon-theme-light-600),
  700: var(--avelon-theme-light-700),
  800: var(--avelon-theme-light-800),
  900: var(--avelon-theme-light-900),
  A100: var(--avelon-theme-light-A100),
  A200: var(--avelon-theme-light-A200),
  A400: var(--avelon-theme-light-A400),
  A700: var(--avelon-theme-light-A700),
  contrast: (
    50: var(--avelon-theme-light-contrast-50),
    100: var(--avelon-theme-light-contrast-100),
    200: var(--avelon-theme-light-contrast-200),
    300: var(--avelon-theme-light-contrast-300),
    400: var(--avelon-theme-light-contrast-400),
    500: var(--avelon-theme-light-contrast-500),
    600: var(--avelon-theme-light-contrast-600),
    700: var(--avelon-theme-light-contrast-700),
    800: var(--avelon-theme-light-contrast-800),
    900: var(--avelon-theme-light-contrast-900),
    A100: var(--avelon-theme-light-contrast-A100),
    A200: var(--avelon-theme-light-contrast-A200),
    A400: var(--avelon-theme-light-contrast-A400),
    A700: var(--avelon-theme-light-contrast-A700)
  )
);

$avelon-dark-palette: (
  50: var(--avelon-theme-dark-50),
  100: var(--avelon-theme-dark-100),
  200: var(--avelon-theme-dark-200),
  300: var(--avelon-theme-dark-300),
  400: var(--avelon-theme-dark-400),
  500: var(--avelon-theme-dark-500),
  600: var(--avelon-theme-dark-600),
  700: var(--avelon-theme-dark-700),
  800: var(--avelon-theme-dark-800),
  900: var(--avelon-theme-dark-900),
  A100: var(--avelon-theme-dark-A100),
  A200: var(--avelon-theme-dark-A200),
  A400: var(--avelon-theme-dark-A400),
  A700: var(--avelon-theme-dark-A700),
  contrast: (
    50: var(--avelon-theme-dark-contrast-50),
    100: var(--avelon-theme-dark-contrast-100),
    200: var(--avelon-theme-dark-contrast-200),
    300: var(--avelon-theme-dark-contrast-300),
    400: var(--avelon-theme-dark-contrast-400),
    500: var(--avelon-theme-dark-contrast-500),
    600: var(--avelon-theme-dark-contrast-600),
    700: var(--avelon-theme-dark-contrast-700),
    800: var(--avelon-theme-dark-contrast-800),
    900: var(--avelon-theme-dark-contrast-900),
    A100: var(--avelon-theme-dark-contrast-A100),
    A200: var(--avelon-theme-dark-contrast-A200),
    A400: var(--avelon-theme-dark-contrast-A400),
    A700: var(--avelon-theme-dark-contrast-A700)
  )
);

@include mat.core();

$avelon-light-theme-primary: mat.define-palette($avelon-light-palette, 200, 300, 400); // form fields
$avelon-light-theme-accent: mat.define-palette($avelon-light-palette, A100, A200, A400); // checkboxes
$avelon-light-theme-warn: mat.define-palette($avelon-light-palette, A700); // validation errors

$avelon-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $avelon-light-theme-primary,
      accent: $avelon-light-theme-accent,
      warn: $avelon-light-theme-warn
    ),
    density: -1
  )
);

$avelon-dark-theme-primary: mat.define-palette($avelon-dark-palette, 200, 300, 400); // form fields
$avelon-dark-theme-accent: mat.define-palette($avelon-dark-palette, A100, A200, A400); // checkboxes
$avelon-dark-theme-warn: mat.define-palette($avelon-dark-palette, A700); // validation erros

$avelon-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $avelon-dark-theme-primary,
      accent: $avelon-dark-theme-accent,
      warn: $avelon-dark-theme-warn
    ),
    density: -1
  )
);

.avelon-theme {
  @include mat.all-component-themes($avelon-light-theme);

  &.avelon-dark-theme {
    @include mat.all-component-colors($avelon-dark-theme);
    color: white;
  }
}

.avelon-theme {
  --view-background: var(--avelon-theme-light-view-background); // background behind cards or on inactive areas
  --window-background: var(--avelon-theme-light-window-background); // lists, trees
  --material-background: var(--avelon-theme-light-material-background); // windows, menus, dropdowns, cards
  --material-input-background: var(--avelon-theme-light-material-input-background);
  --drag-indicator-background: var(--avelon-theme-light-drag-indicator-background);

  --primary-50: var(--avelon-theme-light-50);
  --primary-100: var(--avelon-theme-light-100);
  --primary-200: var(--avelon-theme-light-200);
  --primary-300: var(--avelon-theme-light-300);
  --primary-400: var(--avelon-theme-light-400);
  --primary-500: var(--avelon-theme-light-500);
  --primary-600: var(--avelon-theme-light-600);
  --primary-700: var(--avelon-theme-light-700);
  --primary-800: var(--avelon-theme-light-800);
  --primary-900: var(--avelon-theme-light-900);
  --primary-contrast-50: var(--avelon-theme-light-contrast-50);
  --primary-contrast-100: var(--avelon-theme-light-contrast-100);
  --primary-contrast-200: var(--avelon-theme-light-contrast-200);
  --primary-contrast-300: var(--avelon-theme-light-contrast-300);
  --primary-contrast-400: var(--avelon-theme-light-contrast-400);
  --primary-contrast-500: var(--avelon-theme-light-contrast-500);
  --primary-contrast-600: var(--avelon-theme-light-contrast-600);
  --primary-contrast-700: var(--avelon-theme-light-contrast-700);
  --primary-contrast-800: var(--avelon-theme-light-contrast-800);
  --primary-contrast-900: var(--avelon-theme-light-contrast-900);

  --accent-200: var(--avelon-theme-light-A100);
  --accent-300: var(--avelon-theme-light-A200);
  --accent-400: var(--avelon-theme-light-A400);
  --accent-700: var(--avelon-theme-light-A700);
  --accent-contrast-200: var(--avelon-theme-light-contrast-A100);
  --accent-contrast-300: var(--avelon-theme-light-contrast-A200);
  --accent-contrast-400: var(--avelon-theme-light-contrast-A400);
  --accent-contrast-700: var(--avelon-theme-light-contrast-A700);

  --blue: var(--avelon-theme-light-blue);
  --green: var(--avelon-theme-light-green);
  --yellow: var(--avelon-theme-light-yellow);
  --orange: var(--avelon-theme-light-orange);
  --red: var(--avelon-theme-light-red);
  --blue-contrast: var(--avelon-theme-light-blue-contrast);
  --green-contrast: var(--avelon-theme-light-green-contrast);
  --yellow-contrast: var(--avelon-theme-light-yellow-contrast);
  --orange-contrast: var(--avelon-theme-light-orange-contrast);
  --red-contrast: var(--avelon-theme-light-red-contrast);

  --tint-blue: var(--avelon-theme-light-tint-blue);
  --tint-green: var(--avelon-theme-light-tint-green);
  --tint-yellow: var(--avelon-theme-light-tint-yellow);
  --tint-orange: var(--avelon-theme-light-tint-orange);
  --tint-red: var(--avelon-theme-light-tint-red);
  --tint-blue-contrast: var(--avelon-theme-light-tint-blue-contrast);
  --tint-green-contrast: var(--avelon-theme-light-tint-green-contrast);
  --tint-yellow-contrast: var(--avelon-theme-light-tint-yellow-contrast);
  --tint-orange-contrast: var(--avelon-theme-light-tint-orange-contrast);
  --tint-red-contrast: var(--avelon-theme-light-tint-red-contrast);

  --message-blue: var(--avelon-theme-light-message-blue);
  --message-green: var(--avelon-theme-light-message-green);
  --message-yellow: var(--avelon-theme-light-message-yellow);
  --message-orange: var(--avelon-theme-light-message-orange);
  --message-red: var(--avelon-theme-light-message-red);
  --message-blue-contrast: var(--avelon-theme-light-message-blue-contrast);
  --message-green-contrast: var(--avelon-theme-light-message-green-contrast);
  --message-yellow-contrast: var(--avelon-theme-light-message-yellow-contrast);
  --message-orange-contrast: var(--avelon-theme-light-message-orange-contrast);
  --message-red-contrast: var(--avelon-theme-light-message-red-contrast);

  --edit-mode: var(--orange);
  --edit-mode-contrast: var(--orange-contrast);
  --save: var(--blue);
  --save-contrast: var(--blue-contrast);

  --topbar: var(--avelon-theme-light-topbar);
  --topbar-text: var(--avelon-theme-light-topbar-text);

  --text: var(--avelon-theme-light-text);
  --text-disabled: var(--avelon-theme-light-text-disabled);
  --link: var(--avelon-theme-light-link);
  --icon-hue: var(--avelon-theme-light-icon-hue);
  --icon-hue-disabled: var(--avelon-theme-light-icon-hue-disabled);
  --hover: var(--avelon-theme-light-hover);
  --hover-text: var(--avelon-theme-light-hover-text);
  --selected: var(--avelon-theme-light-selected);
  --selected-text: var(--avelon-theme-light-selected-text);
  --selected-shape-fill: var(--avelon-theme-light-selected-shape-fill);
  --selected-shape-stroke: var(--avelon-theme-light-selected-shape-stroke);
  --label-text: var(--avelon-theme-light-label-text);
  --box-border: var(--avelon-theme-light-box-border);
  --box-transparency: var(--avelon-theme-light-box-transparency);

  --light-widget-hue: rgb(255, 255, 255);
  --light-widget-hue-semi-transparent: rgba(255, 255, 255, 0.5);
  --medium-widget-hue: #e6e6e6;
  --dark-widget-hue: #cccccc;
  --widget-overlay-even: rgba(255, 255, 255, 0.8);
  --widget-overlay-even-hover: rgb(235, 235, 235, 0.8);
  --widget-overlay-odd: rgba(255, 255, 255, 0.9);
  --widget-overlay-odd-hover: rgba(235, 235, 235, 0.9);

  --mdc-theme-text-primary-on-background: var(--avelon-theme-light-text);
  --mdc-theme-text-disabled-on-background: var(--avelon-theme-light-text-disabled);
}

.avelon-theme.avelon-dark-theme {
  --view-background: var(--avelon-theme-dark-view-background); // background behind cards or on inactive areas
  --window-background: var(--avelon-theme-dark-window-background); // lists, trees
  --material-background: var(--avelon-theme-dark-material-background); // windows, menus, dropdowns, cards
  --material-input-background: var(--avelon-theme-dark-material-input-background);
  --drag-indicator-background: var(--avelon-theme-dark-drag-indicator-background);

  --primary-50: var(--avelon-theme-dark-50);
  --primary-100: var(--avelon-theme-dark-100);
  --primary-200: var(--avelon-theme-dark-200);
  --primary-300: var(--avelon-theme-dark-300);
  --primary-400: var(--avelon-theme-dark-400);
  --primary-500: var(--avelon-theme-dark-500);
  --primary-600: var(--avelon-theme-dark-600);
  --primary-700: var(--avelon-theme-dark-700);
  --primary-800: var(--avelon-theme-dark-800);
  --primary-900: var(--avelon-theme-dark-900);
  --primary-contrast-50: var(--avelon-theme-dark-contrast-50);
  --primary-contrast-100: var(--avelon-theme-dark-contrast-100);
  --primary-contrast-200: var(--avelon-theme-dark-contrast-200);
  --primary-contrast-300: var(--avelon-theme-dark-contrast-300);
  --primary-contrast-400: var(--avelon-theme-dark-contrast-400);
  --primary-contrast-500: var(--avelon-theme-dark-contrast-500);
  --primary-contrast-600: var(--avelon-theme-dark-contrast-600);
  --primary-contrast-700: var(--avelon-theme-dark-contrast-700);
  --primary-contrast-800: var(--avelon-theme-dark-contrast-800);
  --primary-contrast-900: var(--avelon-theme-dark-contrast-900);

  --accent-200: var(--avelon-theme-dark-A100);
  --accent-300: var(--avelon-theme-dark-A200);
  --accent-400: var(--avelon-theme-dark-A400);
  --accent-700: var(--avelon-theme-dark-A700);
  --accent-contrast-200: var(--avelon-theme-dark-contrast-A100);
  --accent-contrast-300: var(--avelon-theme-dark-contrast-A200);
  --accent-contrast-400: var(--avelon-theme-dark-contrast-A400);
  --accent-contrast-700: var(--avelon-theme-dark-contrast-A700);

  --blue: var(--avelon-theme-dark-blue);
  --green: var(--avelon-theme-dark-green);
  --yellow: var(--avelon-theme-dark-yellow);
  --orange: var(--avelon-theme-dark-orange);
  --red: var(--avelon-theme-dark-red);
  --blue-contrast: var(--avelon-theme-dark-blue-contrast);
  --green-contrast: var(--avelon-theme-dark-green-contrast);
  --yellow-contrast: var(--avelon-theme-dark-yellow-contrast);
  --orange-contrast: var(--avelon-theme-dark-orange-contrast);
  --red-contrast: var(--avelon-theme-dark-red-contrast);

  --tint-blue: var(--avelon-theme-dark-tint-blue);
  --tint-green: var(--avelon-theme-dark-tint-green);
  --tint-yellow: var(--avelon-theme-dark-tint-yellow);
  --tint-orange: var(--avelon-theme-dark-tint-orange);
  --tint-red: var(--avelon-theme-dark-tint-red);
  --tint-blue-contrast: var(--avelon-theme-dark-tint-blue-contrast);
  --tint-green-contrast: var(--avelon-theme-dark-tint-green-contrast);
  --tint-yellow-contrast: var(--avelon-theme-dark-tint-yellow-contrast);
  --tint-orange-contrast: var(--avelon-theme-dark-tint-orange-contrast);
  --tint-red-contrast: var(--avelon-theme-dark-tint-red-contrast);

  --message-blue: var(--avelon-theme-dark-message-blue);
  --message-green: var(--avelon-theme-dark-message-green);
  --message-yellow: var(--avelon-theme-dark-message-yellow);
  --message-orange: var(--avelon-theme-dark-message-orange);
  --message-red: var(--avelon-theme-dark-message-red);
  --message-blue-contrast: var(--avelon-theme-dark-message-blue-contrast);
  --message-green-contrast: var(--avelon-theme-dark-message-green-contrast);
  --message-yellow-contrast: var(--avelon-theme-dark-message-yellow-contrast);
  --message-orange-contrast: var(--avelon-theme-dark-message-orange-contrast);
  --message-red-contrast: var(--avelon-theme-dark-message-red-contrast);

  --edit-mode: var(--orange);
  --edit-mode-contrast: var(--orange-contrast);
  --save: var(--blue);
  --save-contrast: var(--blue-contrast);

  --topbar: var(--avelon-theme-dark-topbar);
  --topbar-text: var(--avelon-theme-dark-topbar-text);

  --text: var(--avelon-theme-dark-text);
  --text-disabled: var(--avelon-theme-dark-text-disabled);
  --link: var(--avelon-theme-dark-link);
  --icon-hue: var(--avelon-theme-dark-icon-hue);
  --icon-hue-disabled: var(--avelon-theme-dark-icon-hue-disabled);
  --hover: var(--avelon-theme-dark-hover);
  --hover-text: var(--avelon-theme-dark-hover-text);
  --selected: var(--avelon-theme-dark-selected);
  --selected-text: var(--avelon-theme-dark-selected-text);
  --selected-shape-fill: var(--avelon-theme-dark-selected-shape-fill);
  --selected-shape-stroke: var(--avelon-theme-dark-selected-shape-stroke);
  --label-text: var(--avelon-theme-dark-label-text);
  --box-border: var(--avelon-theme-dark-box-border);
  --box-transparency: var(--avelon-theme-dark-box-transparency);

  --light-widget-hue: rgb(51, 51, 51);
  --light-widget-hue-semi-transparent: rgb(51, 51, 51, 0.5);
  --medium-widget-hue: #2b2b2b;
  --dark-widget-hue: #222222;
  --widget-overlay-even: rgba(43, 43, 43, 0.8);
  --widget-overlay-even-hover: rgba(69, 69, 69, 0.8);
  --widget-overlay-odd: rgba(43, 43, 43, 0.9);
  --widget-overlay-odd-hover: rgba(69, 69, 69, 0.9);

  --mdc-theme-text-primary-on-background: var(--avelon-theme-dark-text);
  --mdc-theme-text-disabled-on-background: var(--avelon-theme-dark-text-disabled);
}
