.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-tooltip {
    backdrop-filter: blur(4px);
    white-space: pre-line;

    .mdc-tooltip__surface {
      max-width: 400px;
    }
  }
}
