@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-toolbar {
    height: $default-mat-toolbar-height;
    background-color: var(--primary-800);
    color: var(--primary-contrast-800);
    font-size: $font-size;
    overflow: hidden;
    padding: 0;
    position: relative;

    &::after {
      position: absolute;
      content: " ";
      height: 0;
      background-color: transparent;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      transition: $smooth-content-transition;
    }

    &.edit-mode {
      padding-bottom: $base-size * 0.5;
      transition: padding-bottom 0.3s $default-easing;
    }

    &.edit-mode::after {
      background-color: var(--edit-mode);
      height: $base-size * 0.5;
    }

    &.edit-mode .quit-edit {
      background-color: var(--edit-mode);
      color: var(--edit-mode-contrast);
    }

    &.mat-primary {
      background-color: var(--primary-900);
      color: var(--primary-contrast-900);
    }

    &.secondary-toolbar {
      background-color: var(--primary-500);
      color: var(--primary-contrast-500);
    }

    .mat-mdc-button,
    .mat-mdc-icon-button {
      border-radius: 0;
      height: $base-height;
    }

    .mat-mdc-icon-button[disabled] mat-icon {
      color: var(--icon-hue-disabled);
    }

    mat-radio-button {
      .mdc-radio__outer-circle,
      .mdc-radio__inner-circle {
        border-color: var(--primary-contrast-400) !important;
      }

      label {
        color: var(--primary-contrast-400) !important;
      }
    }
  }
}
