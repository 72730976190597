@use "sass:math";

$base-size: 8px;
$base-padding: $base-size;
$base-gap: $base-size;
$base-height: 5 * $base-size;
$base-width: 5 * $base-size;
$icon-size: 3 * $base-size;
$large-icon-size: 2 * $icon-size;

$default-sidebar-width: 320px;
$default-mat-toolbar-height: $base-height;
$scroll-width: 17px;

$row-width: 100%;

$default-easing: cubic-bezier(0, 0, 0.2, 1); // Material deceleration curve
$inverse-easing: cubic-bezier(0.4, 0, 0.6, 1); // Material sharp curve
$smooth-content-transition: all 0.5s $default-easing;

// Font
$font-family: "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
$font-family-mono: "Roboto Mono", "Consolas", monospace;
$font-size: 14px;
$label-font-scale: math.div(10, 12);
$label-font-size: $font-size * $label-font-scale;
$bold-font-weight: 600;

$mat-form-field-bottom-padding: 19.3333px; // height of .mat-mdc-form-field-bottom-align
