@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-checkbox {
    &.root-only {
      @include root-only()
    }

    &.mat-accent {
      --mdc-checkbox-selected-checkmark-color: var(--accent-contrast-200);
    }
  }

  .mat-mdc-checkbox.mat-accent.mdc-checkbox--disabled {
    pointer-events: auto;

    .mat-mdc-checkbox-touch-target,
    label,
    label > span {
      cursor: not-allowed;
    }
  }
}
