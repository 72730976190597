@mixin mat-slide-toggle(
  $selected-handle-color,
  $selected-track-color,
  $unselected-handle-color,
  $unselected-track-color,
  $selected-icon-color,
  $unselected-icon-color,
  $text-color
) {
  @if $selected-handle-color != null {
    --mdc-switch-selected-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-focus-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-hover-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-pressed-handle-color: #{$selected-handle-color} !important;
  }

  @if $selected-track-color != null {
    --mdc-switch-selected-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-focus-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-hover-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-pressed-track-color: #{$selected-track-color} !important;
  }

  @if $unselected-handle-color != null {
    --mdc-switch-unselected-handle-color: #{$unselected-handle-color} !important;
    --mdc-switch-unselected-focus-handle-color: #{$unselected-handle-color} !important;
    --mdc-switch-unselected-hover-handle-color: #{$unselected-handle-color} !important;
    --mdc-switch-unselected-pressed-handle-color: #{$unselected-handle-color} !important;
  }

  @if $unselected-track-color != null {
    --mdc-switch-unselected-track-color: #{$unselected-track-color} !important;
    --mdc-switch-unselected-focus-track-color: #{$unselected-track-color} !important;
    --mdc-switch-unselected-hover-track-color: #{$unselected-track-color} !important;
    --mdc-switch-unselected-pressed-track-color: #{$unselected-track-color} !important;
  }

  @if $text-color != null {
    --mdc-theme-text-primary-on-background: #{$text-color} !important;
  }
  @if $selected-icon-color != null {
    --mdc-switch-selected-icon-color: #{$selected-icon-color} !important;
  }
  @if $unselected-icon-color != null {
    --mdc-switch-unselected-icon-color: #{$unselected-icon-color} !important;
  }
}

.avelon-theme {
  mat-slide-toggle.mat-mdc-slide-toggle {
    @include mat-slide-toggle(
      $selected-handle-color: var(--primary-900),
      $selected-track-color: var(--primary-400),
      $unselected-handle-color: var(--primary-500),
      $unselected-track-color: var(--primary-100),
      $selected-icon-color: #fff,
      $unselected-icon-color: #fff,
      $text-color: var(--text)
    );
  }

  mat-toolbar mat-slide-toggle.mat-mdc-slide-toggle {
    @include mat-slide-toggle(
      $selected-handle-color: var(--primary-900),
      $selected-track-color: var(--primary-800),
      $unselected-handle-color: var(--primary-400),
      $unselected-track-color: var(--primary-100),
      $selected-icon-color: var(--primary-200),
      $unselected-icon-color: var(--primary-900),
      $text-color: var(--primary-contrast-500)
    );
  }
}

.avelon-theme.avelon-dark-theme {
  mat-toolbar mat-slide-toggle.mat-mdc-slide-toggle,
  mat-slide-toggle.mat-mdc-slide-toggle {
    @include mat-slide-toggle(
      $selected-handle-color: var(--primary-contrast-500),
      $selected-track-color: var(--primary-900),
      $unselected-handle-color: var(--primary-400),
      $unselected-track-color: var(--primary-200),
      $selected-icon-color: var(--primary-600),
      $unselected-icon-color: var(--primary-900),
      $text-color: var(--primary-contrast-500)
    );
  }
}
