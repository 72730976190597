@import "variables";

@font-face {
  font-family: "alcedo";
  src: url("../assets/fonts/alcedo.woff2?txr4wo") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="alcedo-icon-"],
[class*=" alcedo-icon-"],
.alcedo-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "alcedo" !important;
  speak: never;
  font-size: $icon-size;
  font-style: normal;
  font-weight: 200;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.alcedo-icon-avelon:before {
  content: "\e900";
}
.alcedo-icon-ticket-status-open:before {
  content: "\e607";
}
.alcedo-icon-ticket-status-open-filled:before {
  content: "\e901";
}
.alcedo-icon-ticket-status-warning:before {
  content: "\e98a";
}
.alcedo-icon-ticket-status-warning-filled:before {
  content: "\e903";
}
.alcedo-icon-ticket-status-acknowledged:before {
  content: "\e992";
}
.alcedo-icon-ticket-status-acknowledged-filled:before {
  content: "\e904";
}
.alcedo-icon-ticket-status-gone:before {
  content: "\e98b";
}
.alcedo-icon-ticket-status-gone-filled:before {
  content: "\e905";
}
.alcedo-icon-ticket-status-gone-and-acknowledged:before {
  content: "\e98c";
}
.alcedo-icon-ticket-status-gone-and-acknowledged-filled:before {
  content: "\e9c3";
}
.alcedo-icon-ticket-status-info:before {
  content: "\e98d";
}
.alcedo-icon-ticket-status-info-filled:before {
  content: "\e906";
}
.alcedo-icon-ticket-status-closed:before {
  content: "\e98e";
}
.alcedo-icon-ticket-status-repeated:before {
  content: "\e98f";
}
.alcedo-icon-ticket-status-comment:before {
  content: "\e990";
}
.alcedo-icon-tool-pointer:before {
  content: "\e600";
}
.alcedo-icon-tool-hand:before {
  content: "\e601";
}
.alcedo-icon-tool-line:before {
  content: "\e602";
}
.alcedo-icon-tool-ellipse:before {
  content: "\e603";
}
.alcedo-icon-tool-rectangle:before {
  content: "\e604";
}
.alcedo-icon-tool-polygon:before {
  content: "\e970";
}
.alcedo-icon-tool-text:before {
  content: "\e605";
}
.alcedo-icon-tool-image:before {
  content: "\e606";
}
.alcedo-icon-tool-chart:before {
  content: "\e608";
}
.alcedo-icon-tool-link:before {
  content: "\e609";
}
.alcedo-icon-tool-link-group:before {
  content: "\e60a";
}
.alcedo-icon-bring-to-front:before {
  content: "\e956";
}
.alcedo-icon-send-to-back:before {
  content: "\e957";
}
.alcedo-icon-group:before {
  content: "\e958";
}
.alcedo-icon-ungroup:before {
  content: "\e959";
}
.alcedo-icon-reset-size:before {
  content: "\e96f";
}
.alcedo-icon-devices:before {
  content: "\e940";
}
.alcedo-icon-data-point:before {
  content: "\e60c";
}
.alcedo-icon-data-point-surrogate:before {
  content: "\e991";
}
.alcedo-icon-shape:before {
  content: "\e60d";
}
.alcedo-icon-shape-broken:before {
  content: "\e995";
}
.alcedo-icon-sidebar-gallery:before {
  content: "\e60e";
}
.alcedo-icon-shape-editor:before {
  content: "\e97c";
}
.alcedo-icon-dashboard-layout-fit:before {
  content: "\e60f";
}
.alcedo-icon-dashboard-layout-scrollable:before {
  content: "\e610";
}
.alcedo-icon-dashboard-layout-responsive:before {
  content: "\e611";
}
.alcedo-icon-file-type-default:before {
  content: "\e90e";
}
.alcedo-icon-file-type-base:before {
  content: "\e909";
}
.alcedo-icon-file-type-document:before {
  content: "\e90a";
}
.alcedo-icon-file-type-spreadsheet:before {
  content: "\e90b";
}
.alcedo-icon-file-type-presentation:before {
  content: "\e90c";
}
.alcedo-icon-file-type-pdf:before {
  content: "\e90d";
}
.alcedo-icon-file-type-image:before {
  content: "\e90f";
}
.alcedo-icon-color-fill:before {
  content: "\e910";
}
.alcedo-icon-color-border:before {
  content: "\e913";
}
.alcedo-icon-color-text:before {
  content: "\e914";
}
.alcedo-icon-color-box:before {
  content: "\e915";
}
.alcedo-icon-data-type-acceleration:before {
  content: "\e912";
}
.alcedo-icon-data-type-angle:before {
  content: "\e916";
}
.alcedo-icon-data-type-angular-momentum:before {
  content: "\e917";
}
.alcedo-icon-data-type-area:before {
  content: "\e918";
}
.alcedo-icon-data-type-boolean:before {
  content: "\e919";
}
.alcedo-icon-data-type-capacitance:before {
  content: "\e91a";
}
.alcedo-icon-data-type-compressibility:before {
  content: "\e91b";
}
.alcedo-icon-data-type-conductance:before {
  content: "\e91c";
}
.alcedo-icon-data-type-conductivity:before {
  content: "\e91d";
}
.alcedo-icon-data-type-cooling-rate:before {
  content: "\e91e";
}
.alcedo-icon-data-type-currency:before {
  content: "\e91f";
}
.alcedo-icon-data-type-current:before {
  content: "\e920";
}
.alcedo-icon-data-type-current-density:before {
  content: "\e921";
}
.alcedo-icon-data-type-data-transfer-speed:before {
  content: "\e922";
}
.alcedo-icon-data-type-data-volume:before {
  content: "\e923";
}
.alcedo-icon-data-type-degree-days:before {
  content: "\e924";
}
.alcedo-icon-data-type-density:before {
  content: "\e925";
}
.alcedo-icon-data-type-distance:before {
  content: "\e926";
}
.alcedo-icon-data-type-double:before {
  content: "\e927";
}
.alcedo-icon-data-type-electric-field-strength:before {
  content: "\e928";
}
.alcedo-icon-data-type-energy:before {
  content: "\e929";
}
.alcedo-icon-data-type-entropy:before {
  content: "\e92a";
}
.alcedo-icon-data-type-float:before {
  content: "\e92b";
}
.alcedo-icon-data-type-force:before {
  content: "\e92c";
}
.alcedo-icon-data-type-frequency:before {
  content: "\e92d";
}
.alcedo-icon-data-type-illuminance:before {
  content: "\e92e";
}
.alcedo-icon-data-type-inductance:before {
  content: "\e92f";
}
.alcedo-icon-data-type-insolation:before {
  content: "\e930";
}
.alcedo-icon-data-type-integer:before {
  content: "\e931";
}
.alcedo-icon-data-type-irradiance:before {
  content: "\e932";
}
.alcedo-icon-data-type-long:before {
  content: "\e933";
}
.alcedo-icon-data-type-luminance:before {
  content: "\e934";
}
.alcedo-icon-data-type-luminous-flux:before {
  content: "\e935";
}
.alcedo-icon-data-type-luminous-intensity:before {
  content: "\e936";
}
.alcedo-icon-data-type-magnetic-field-strength:before {
  content: "\e937";
}
.alcedo-icon-data-type-magnetic-moment:before {
  content: "\e938";
}
.alcedo-icon-data-type-mass:before {
  content: "\e939";
}
.alcedo-icon-data-type-mass-flow:before {
  content: "\e93a";
}
.alcedo-icon-data-type-momentum:before {
  content: "\e93b";
}
.alcedo-icon-data-type-percent:before {
  content: "\e93c";
}
.alcedo-icon-data-type-power:before {
  content: "\e93d";
}
.alcedo-icon-data-type-power-level:before {
  content: "\e93e";
}
.alcedo-icon-data-type-pressure:before {
  content: "\e93f";
}
.alcedo-icon-data-type-relative-humidity:before {
  content: "\e941";
}
.alcedo-icon-data-type-resistance:before {
  content: "\e942";
}
.alcedo-icon-data-type-resistivity:before {
  content: "\e943";
}
.alcedo-icon-data-type-specific-enthalpy:before {
  content: "\e944";
}
.alcedo-icon-data-type-specific-heat:before {
  content: "\e945";
}
.alcedo-icon-data-type-string:before {
  content: "\e946";
}
.alcedo-icon-data-type-surface-tension:before {
  content: "\e947";
}
.alcedo-icon-data-type-temperature:before {
  content: "\e948";
}
.alcedo-icon-data-type-temperature-departure:before {
  content: "\e949";
}
.alcedo-icon-data-type-temperature-difference:before {
  content: "\e94a";
}
.alcedo-icon-data-type-thermal-conductivity:before {
  content: "\e94b";
}
.alcedo-icon-data-type-thermal-transmittance:before {
  content: "\e94c";
}
.alcedo-icon-data-type-thomson-coefficient:before {
  content: "\e94d";
}
.alcedo-icon-data-type-time:before {
  content: "\e94e";
}
.alcedo-icon-data-type-time-span:before {
  content: "\e94f";
}
.alcedo-icon-data-type-unsigned-integer:before {
  content: "\e950";
}
.alcedo-icon-data-type-unsigned-long:before {
  content: "\e951";
}
.alcedo-icon-data-type-velocity:before {
  content: "\e952";
}
.alcedo-icon-data-type-voltage:before {
  content: "\e953";
}
.alcedo-icon-data-type-volume:before {
  content: "\e954";
}
.alcedo-icon-data-type-volume-current:before {
  content: "\e955";
}
.alcedo-icon-report-job:before {
  content: "\e95a";
}
.alcedo-icon-medium-air:before {
  content: "\e95b";
}
.alcedo-icon-medium-air-compressed:before {
  content: "\e95c";
}
.alcedo-icon-medium-chemical:before {
  content: "\e95d";
}
.alcedo-icon-medium-electricity:before {
  content: "\e95e";
}
.alcedo-icon-medium-gas:before {
  content: "\e95f";
}
.alcedo-icon-medium-heating-energy:before {
  content: "\e960";
}
.alcedo-icon-medium-cooling-energy:before {
  content: "\e994";
}
.alcedo-icon-medium-water:before {
  content: "\e961";
}
.alcedo-icon-medium-water-waste:before {
  content: "\e962";
}
.alcedo-icon-priority-array:before {
  content: "\e97b";
}
.alcedo-icon-priority-automation:before {
  content: "\e977";
}
.alcedo-icon-priority-operating:before {
  content: "\e978";
}
.alcedo-icon-priority-operating-clear:before {
  content: "\e963";
}
.alcedo-icon-priority-safety:before {
  content: "\e979";
}
.alcedo-icon-priority-safety-with-manual-intervention:before {
  content: "\e97a";
}
.alcedo-icon-relinquish-default:before {
  content: "\e987";
}
.alcedo-icon-manual-mode-none:before {
  content: "\e964";
}
.alcedo-icon-manual-mode:before {
  content: "\e965";
}
.alcedo-icon-manual-mode-filled:before {
  content: "\e911";
}
.alcedo-icon-manual-mode-lower:before {
  content: "\e966";
}
.alcedo-icon-manual-mode-override:before {
  content: "\e967";
}
.alcedo-icon-manual-mode-both:before {
  content: "\e968";
}
.alcedo-icon-manual-mode-clear:before {
  content: "\e969";
}
.alcedo-icon-operation-active:before {
  content: "\e96a";
}
.alcedo-icon-operation-out-of-service:before {
  content: "\e96b";
}
.alcedo-icon-data-point-energy-management:before {
  content: "\e96c";
}
.alcedo-icon-data-point-operate:before {
  content: "\e96d";
}
.alcedo-icon-data-point-alarm:before {
  content: "\e96e";
}
.alcedo-icon-data-point-automated-writing:before {
  content: "\e999";
}
.alcedo-icon-data-point-meter-readings:before {
  content: "\e971";
}
.alcedo-icon-data-point-published-to-bacnet:before {
  content: "\e972";
}
.alcedo-icon-notification-email:before {
  content: "\e973";
}
.alcedo-icon-notification-email-off:before {
  content: "\e974";
}
.alcedo-icon-notification-phone:before {
  content: "\e975";
}
.alcedo-icon-notification-phone-off:before {
  content: "\e976";
}
.alcedo-icon-notification-report:before {
  content: "\e97d";
}
.alcedo-icon-notification-report-off:before {
  content: "\e97e";
}
.alcedo-icon-calendar-weekly:before {
  content: "\e97f";
}
.alcedo-icon-calendar-exception:before {
  content: "\e980";
}
.alcedo-icon-calendar-exception-filled:before {
  content: "\e907";
}
.alcedo-icon-calendar-exception-new:before {
  content: "\e981";
}
.alcedo-icon-calendar-reference:before {
  content: "\e982";
}
.alcedo-icon-calendar-reference-filled:before {
  content: "\e908";
}
.alcedo-icon-alarm-chain:before {
  content: "\e983";
}
.alcedo-icon-alarm-chain-shared:before {
  content: "\e984";
}
.alcedo-icon-user:before {
  content: "\e99a";
}
.alcedo-icon-user-shared:before {
  content: "\e99b";
}
.alcedo-icon-user-group:before {
  content: "\e985";
}
.alcedo-icon-user-group-shared:before {
  content: "\e986";
}
.alcedo-icon-user-role:before {
  content: "\e988";
}
.alcedo-icon-user-role-shared:before {
  content: "\e989";
}
.alcedo-icon-binoculars:before {
  content: "\e9c2";
}
.alcedo-icon-records-interpolate-measurements:before {
  content: "\e993";
}
.alcedo-icon-records-copy-measurements:before {
  content: "\e996";
}
.alcedo-icon-save-date-time:before {
  content: "\e997";
}
.alcedo-icon-rename:before {
  content: "\e902";
}
.alcedo-icon-edit-group:before {
  content: "\e998";
}
.alcedo-icon-mouse:before {
  content: "\e99c";
}
.alcedo-icon-mouse-left:before {
  content: "\e99d";
}
.alcedo-icon-mouse-middle:before {
  content: "\e99e";
}
.alcedo-icon-mouse-right:before {
  content: "\e99f";
}
.alcedo-icon-mouse-wheel:before {
  content: "\e9a0";
}
.alcedo-icon-opc-object:before {
  content: "\e9a1";
}
.alcedo-icon-opc-property:before {
  content: "\e9a2";
}
.alcedo-icon-opc-method:before {
  content: "\e9a3";
}
.alcedo-icon-opc-alias:before {
  content: "\e9a4";
}
.alcedo-icon-opc-object-type:before {
  content: "\e9a5";
}
.alcedo-icon-cdp-base:before {
  content: "\e9a6";
}
.alcedo-icon-cdp-border:before {
  content: "\e9a7";
}
.alcedo-icon-cdp-compound:before {
  content: "\e9a8";
}
.alcedo-icon-cdp-sum:before {
  content: "\e9a9";
}
.alcedo-icon-cdp-subtraction:before {
  content: "\e9aa";
}
.alcedo-icon-cdp-product:before {
  content: "\e9ab";
}
.alcedo-icon-cdp-division:before {
  content: "\e9ac";
}
.alcedo-icon-cdp-function:before {
  content: "\e9ad";
}
.alcedo-icon-cdp-equal:before {
  content: "\e9ae";
}
.alcedo-icon-cdp-not-equal:before {
  content: "\e9af";
}
.alcedo-icon-cdp-smaller:before {
  content: "\e9b0";
}
.alcedo-icon-cdp-smaller-equal:before {
  content: "\e9b1";
}
.alcedo-icon-cdp-greater:before {
  content: "\e9b2";
}
.alcedo-icon-cdp-greater-equal:before {
  content: "\e9b3";
}
.alcedo-icon-cdp-accumulation:before {
  content: "\e9b4";
}
.alcedo-icon-cdp-difference:before {
  content: "\e9b5";
}
.alcedo-icon-cdp-and:before {
  content: "\e9b6";
}
.alcedo-icon-cdp-or:before {
  content: "\e9b7";
}
.alcedo-icon-cdp-not:before {
  content: "\e9b8";
}
.alcedo-icon-cdp-if:before {
  content: "\e9b9";
}
.alcedo-icon-cdp-average:before {
  content: "\e9ba";
}
.alcedo-icon-cdp-minimum:before {
  content: "\e9bb";
}
.alcedo-icon-cdp-maximum:before {
  content: "\e9bc";
}
.alcedo-icon-cdp-quantized-sum:before {
  content: "\e9bd";
}
.alcedo-icon-cdp-periodic-difference:before {
  content: "\e9be";
}
.alcedo-icon-cdp-operating-hours:before {
  content: "\e9bf";
}
.alcedo-icon-cdp-time-shift-dynamic:before {
  content: "\e9c0";
}
.alcedo-icon-cdp-time-shift-fixed-year:before {
  content: "\e9c1";
}
