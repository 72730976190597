@import "variables";

.collapsible-sidebar-view {
  display: flex;
  overflow: hidden;

  .collapsible-sidebar {
    flex: 0 1 auto;
    width: $base-width;
    position: relative;
    transition: $smooth-content-transition;

    &.show {
      min-width: 300px;

      .mat-boolbar {
        border-right: 1px solid var(--primary-200);
      }

      .sidebar-list {
        border-right: 1px solid var(--primary-200);
      }
    }

    .content {
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 1fr auto;

      &:not(.show) {
        .nav-left,
        .sidebar-actions,
        alc-search-filter {
          display: none;
        }
      }

      &.show .nav-right {
        display: none;
      }
    }

    .mat-toolbar {
      display: grid;
      grid-column: 1/3;
      grid-row: 1/2;
      grid-template-columns: auto 1fr auto auto;
      width: 100%;
    }

    .sidebar-list {
      grid-row: 2/3;
      grid-column: 1/2;
    }

    .gap {
      background-color: var(--primary-400);
      grid-row: 2/3;
      grid-column: 2/3;
      height: 100%;
      width: $base-width;
    }
  }

  .view-content {
    display: flex;
    flex: 1 auto;
    overflow: hidden;
  }
}
