@import "variables";
@import "scss/root-only/root-only.mixin";


.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-form-field {
    width: 100%;
    /* We need a minimum because of the mat-select, since the mat-option elements may become very small (e.g. schematic
    details sidebar), since the they have the same width as mat-select. */
    min-width: 150px;
  }

  .mat-mdc-form-field.root-only .mat-mdc-text-field-wrapper {
    @include root-only()
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-icon-suffix {
    color: var(--icon-hue);
  }

  .mat-mdc-form-field-text-suffix,
  .mat-mdc-form-field-text-prefix {
    white-space: nowrap;
  }

  .mat-mdc-form-field-icon-prefix {
    padding-left: $base-padding;
  }

  .mat-mdc-form-field.mat-form-field-disabled {
    .mat-mdc-form-field-infix,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-text-prefix {
      color: var(--text-disabled);

      .mdc-icon-button:not([disabled]) {
        color: var(--icon-hue);
      }
    }
  }


  .mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    &:not(.mat-form-field-invalid) mat-label {
      color: var(--label-text);
    }
  }

  mat-form-field:not(.mat-form-field-invalid) .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--label-text);
  }

  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mdc-text-field .mdc-text-field__input {
    caret-color: var(--text);
  }

  // This is needed for long error messages that overflow over other elements
  .mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    &::before {
      content: none;
    }

    .mat-mdc-form-field-error-wrapper {
      position: relative;
    }
  }
}
