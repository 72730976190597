@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-chip-set .mat-mdc-chip {
    height: 3 * $base-size;

    .mdc-evolution-chip__action {
      cursor: inherit;
    }

    .mdc-evolution-chip__cell--trailing {
      cursor: pointer;

      .mat-mdc-chip-remove {
        opacity: 1;
      }
    }
  }
}
