@import "variables";

.sidebar-search.grid-main-with-sidebar {
  .grid-item-sidebar {
    display: grid;
    grid-template-rows: min-content 1fr;
    overflow: hidden;

    .mat-toolbar {
      .mat-mdc-button {
        height: $default-mat-toolbar-height;
        min-width: 0;
        padding: 0;

        .mat-icon {
          vertical-align: text-top;
          padding: 0 $base-padding * 0.5;
        }
      }
    }
  }
}
